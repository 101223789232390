import { Injectable } from '@angular/core';
import { SyncableSlide } from '../../slides/entities/syncable-slide.interface';
import { UserProfile } from '../../user/entities/user-profile.interface';
import { LocalStorageKey } from '../entities/local-storage-key.enum';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {

    public getAuthToken(): string | null {
        return localStorage.getItem(LocalStorageKey.TOKEN);
    }

    public setAuthToken(token: string | null): void {
        this.setItem(LocalStorageKey.TOKEN, token)
    }

    public getUserProfile(): UserProfile | null {
        const value = localStorage.getItem(LocalStorageKey.USER_DATA);

        return value ? JSON.parse(value) : null;
    }

    public setUserData(userData: UserProfile | null): void {
        if (!userData) {
            localStorage.removeItem(LocalStorageKey.USER_DATA);
        } else {
            localStorage.setItem(LocalStorageKey.USER_DATA, JSON.stringify(userData));
        }
    }

    public getUserId(): string | null {
        return localStorage.getItem(LocalStorageKey.USER_ID);
    }

    public setUserId(userId: string | null): void {
        this.setItem(LocalStorageKey.USER_ID, userId)
    }

    public getDeviceId() {
        return localStorage.getItem(LocalStorageKey.DEVICE_ID);
    }

    public setDeviceId(deviceId: string | null) {
        this.setItem(LocalStorageKey.DEVICE_ID, deviceId);
    }

    private setItem(key: LocalStorageKey, value: string | null) {
        value
            ? localStorage.setItem(key, value)
            : localStorage.removeItem(key);
    }

    public setLastCreatedVideoSlug(slug: string) {
        this.setItem(LocalStorageKey.LAST_CREATED_VIDEO_SLUG, slug);
    }

    public getLastCreatedVideoSlug(): string | null {
        return localStorage.getItem(LocalStorageKey.LAST_CREATED_VIDEO_SLUG);
    }

    public setSlidesChanges(lectureId: number, language: string, slides: SyncableSlide[] | null) {
        if (slides) {
            localStorage.setItem(`${ LocalStorageKey.SLIDE_CHANGES }-${ lectureId }-${ language }`, JSON.stringify(slides));
        } else {
            localStorage.removeItem(`${ LocalStorageKey.SLIDE_CHANGES }-${ lectureId }-${ language }`);
        }
    }

    public getSlidesChanges(lectureId: number, language: string): SyncableSlide[] | null {
        const value = localStorage.getItem(`${ LocalStorageKey.SLIDE_CHANGES }-${ lectureId }-${ language }`);

        return value ? JSON.parse(value) : null;
    }
}
